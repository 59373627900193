import Button from "@/components/common/buttons/button";
import { FormattedMessage } from "react-intl";

export default function CompletedPage({ reset }: { reset: () => void }) {
    return (
        <div className="flex flex-col min-h-full grow justify-between">
            <div className="text-8xl self-center py-8">🎉</div>

            <div className="h-full flex items-center pb-4">
                <div className="flex">
                    <div>
                        <h3 className="text-2xl font-medium">
                            <FormattedMessage id="Thank you for your participation" />
                        </h3>
                        <p className="text-sm pt-2">
                            <FormattedMessage id="You can close this poll now" />
                        </p>
                    </div>
                </div>
            </div>
            <Button
                id="back"
                className="w-full justify-center bg-white/60 hover:bg-white !border-none !shadow-none text-black"
                onClick={reset}
            >
                <FormattedMessage id="Close" />
            </Button>
        </div>
    );
}
