import TextArea from "@/components/common/forms/textarea";
import { Checkbox, Field, Label } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProgressButtons } from "./progressButtons";
import toast from "react-hot-toast";
import { useAxios } from "@/utils/axios";

interface FollowUpInterface {
    previous: () => void;
    next: () => void;
    question: {
        question: string;
        description: string;
        maximum_characters?: number;
        id: string;
    };
    mock?: boolean;
}
export default function FollowUp({
    previous,
    next,
    question,
    mock = false,
}: FollowUpInterface) {
    const axios = useAxios();
    const intl = useIntl();
    const [answer, setAnswer] = useState("");
    const [consent, setConsent] = useState(false);
    const [error, setError] = useState(false);

    const save = async (callback) => {
        if (mock) {
            callback();
        } else {
            try {
                await axios.post(route("polls.user.vote", question.id), {
                    question_data: [answer],
                });
                callback();
            } catch (e) {
                console.error(e);
                toast.error("Saving failed, please try again");
            }
        }
    };

    const characterLimitReached =
        !!question.maximum_characters &&
        answer.length > question.maximum_characters;

    return (
        <>
            <div className="flex flex-col flex-grow-100">
                <div className="flex flex-col pb-6">
                    <span
                        className={`text-xl font-medium break-words ${question.description ? "pb-1" : ""}`}
                    >
                        {question.question}
                    </span>
                    {question.description && <p>{question.description}</p>}
                </div>

                <TextArea
                    className={`w-full resize-none grow !border-none !shadow-none text-black text-sm ${characterLimitReached ? " border-red-600!" : ""}`}
                    rows={7}
                    placeholder={intl.formatMessage({
                        id: "Write your opinion here",
                    })}
                    maxLength={question.maximum_characters ?? undefined}
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                />
                {!!question.maximum_characters && (
                    <div
                        className={`text-xs flex justify-between flex-row-reverse ${characterLimitReached ? "text-red-600" : ""}`}
                    >
                        <span>
                            {answer.length}/{question.maximum_characters}
                        </span>
                        {characterLimitReached && (
                            <span>
                                <FormattedMessage id="Max characters reached" />
                            </span>
                        )}
                    </div>
                )}
                <Field className="flex gap-x-2 pt-2">
                    <Checkbox
                        checked={consent}
                        onChange={(e) => {
                            setConsent(e);
                            if (e) {
                                setError(false);
                            }
                        }}
                        className="group block size-5 min-w-5 min-h-5 rounded-sm border bg-white data-checked:bg-voice cursor-pointer"
                    >
                        <CheckIcon className="text-white opacity-0 group-data-[checked]:opacity-100" />
                    </Checkbox>
                    <Label className="text-sm">
                        <FormattedMessage id="I authorise the use of my answers to Voice" />
                    </Label>
                </Field>
                {error && (
                    <span className="text-xs text-red-600">
                        <FormattedMessage id="Please agree to authorisation before submitting" />
                    </span>
                )}
            </div>
            <ProgressButtons
                previousLabel="Skip"
                previous={previous}
                next={() => {
                    if (characterLimitReached) {
                        /* Do nothing */
                    } else if (consent) {
                        save(() => {
                            next();
                        });
                    } else if (answer === "") {
                        next();
                    } else {
                        setError(true);
                    }
                }}
                nextLabel="Submit"
            />
        </>
    );
}
