import { useEffect, useState } from "react";
import { router, usePage } from "@inertiajs/react";
import CompletedPage from "./cardComponents/completed";
import FollowUp from "./cardComponents/followUp";
import Results from "./cardComponents/results";
import CardFront from "./cardComponents/cardFront";
import { Poll } from "@/types/poll";
import InitialQuestion from "./cardComponents/initialQuestion";
import { needsCompleteRegistration } from "@/utils/registration";
import { AnimatePresence, motion } from "motion/react";
import { colorIsDark } from "@/utils/color";
import dayjs from "dayjs";
interface PollQuestionInterface {
    setHovering: (e: boolean) => void;
    flip: (e: boolean) => void;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    flipped?: boolean;
    mock?: boolean;
    poll: Poll & { imageUrl: string };
    page: number;
    location?: string;
    color: string;
}

interface QuestionWrapperInterface {
    children: any;
    color: string;
    id?: string;
    location?: string;
}

export function QuestionWrapper({
    children,
    color,
    id = "card",
    location,
}: QuestionWrapperInterface) {
    return (
        <motion.div
            layout="size"
            layoutId={`fullCard-${id}-${location}`}
            className={`rounded-2xl flex flex-col overflow-y-auto w-full text-left ${colorIsDark(color) ? "text-white" : "text-black"}`}
            initial={{ minHeight: "24.5rem", height: "100%" }}
            style={{
                backgroundColor: color,
                maxHeight: "100%",
                minHeight: "24.5rem",
            }}
        >
            <div
                style={{ height: "100%" }}
                className="p-5 flex flex-col grow w-full"
            >
                {children}
            </div>
        </motion.div>
    );
}

export function Question({
    setHovering,
    flip,
    page,
    setPage,
    mock = false,
    poll,
    color,
    location,
}: PollQuestionInterface) {
    const [selected, setSelected] = useState<string[]>([]);
    const reset = () => {
        router.reload({
            onSuccess: () => {
                setHovering(false);
                flip(false);
            },
        });
    };

    return (
        <QuestionWrapper
            color={color}
            id={poll.questions[0].id}
            location={location}
        >
            {page === 1 && (
                <InitialQuestion
                    key={1}
                    question={poll.questions[0]}
                    selected={selected}
                    setSelected={setSelected}
                    reset={() => flip(false)}
                    setPage={setPage}
                    mock={mock}
                />
            )}
            {page === 2 && (
                <Results
                    key={2}
                    question={poll.questions[0]}
                    next={() =>
                        setPage((prev) => (poll.questions?.[1] ? prev + 1 : 4))
                    }
                    selected={selected}
                    pollId={poll.id}
                    mock={mock}
                />
            )}
            {page === 3 && (
                <FollowUp
                    key={3}
                    previous={() => setPage((prev) => prev + 1)}
                    next={() => setPage((prev) => prev + 1)}
                    question={poll.questions?.[1]}
                    mock={mock}
                />
            )}
            {page === 4 && <CompletedPage key={4} reset={reset} />}
        </QuestionWrapper>
    );
}

export default function PollCard({
    poll,
    mock = false,
    setCompleteProfileOpen,
    location,
}: {
    poll: Poll & {
        imageUrl: string;
        collaborator: { invertedLogoUrl: string };
        account: { invertedLogoUrl: string };
    };
    mock?: boolean;
    setCompleteProfileOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    location?: string;
}) {
    const [initialRenderFinished, setInitialRenderFinished] = useState(false);

    const { auth } = usePage().props;
    // This state tracks if the user has clicked the card
    const [flipped, setFlipped] = useState(false);
    // This state tracks if the user currently has their mouse over the card
    const [hovering, setHovering] = useState(false);
    const [page, setPage] = useState(0);
    useEffect(() => {
        if ((flipped || hovering) && page === 0) {
            setPage(1);
        }
        if (!flipped && !hovering) {
            setPage(0);
        }
    }, [flipped, hovering, page]);
    useEffect(() => {
        setInitialRenderFinished(true);
    }, []);
    return (
        <motion.div animate="size" className="rounded-[20px]">
            <AnimatePresence mode="wait">
                {page >= 1 ? (
                    <Question
                        flip={setFlipped}
                        setHovering={setHovering}
                        page={page}
                        setPage={setPage}
                        poll={poll}
                        mock={mock}
                        color={poll.color}
                        key="question"
                        location={location}
                    />
                ) : (
                    <CardFront
                        key="front"
                        flip={setFlipped}
                        setCompleteProfileOpen={setCompleteProfileOpen}
                        question={poll.questions[0]}
                        coverImage={poll.imageUrl}
                        voteCount={poll.vote_count}
                        closingAt={poll.closing_at}
                        color={poll.color}
                        logoUrl={
                            poll.account?.invertedLogoUrl ||
                            poll?.collaborator?.invertedLogoUrl
                        }
                        needsCompleteRegistration={needsCompleteRegistration(
                            auth?.user,
                        )}
                        newTag={dayjs(poll.starting_at).isAfter(
                            dayjs().subtract(7, "day"),
                        )}
                        initialRenderFinished={initialRenderFinished}
                        location={location}
                    />
                )}
            </AnimatePresence>
        </motion.div>
    );
}
