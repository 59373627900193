import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ProgressButtons } from "./progressButtons";
import { Checkbox } from "@headlessui/react";
import { optionStyles } from "./optionStyles";
function StyledCheckbox({ enabled, setEnabled, title }) {
    return (
        <Checkbox
            checked={enabled}
            onChange={(included) => setEnabled(title, included)}
            className={`${optionStyles} gap-x-4`}
        >
            <div className="grow max-w-full min-w-0">
                <span className="break-words">{title}</span>
            </div>

            <div className="group block size-6 min-w-6 min-h-6 rounded-sm border-2 bg-white group-data-[checked]:bg-black group-data-[checked]:border-black group-hover:border-black">
                <svg
                    className="stroke-gray-200 opacity-0 group-data-[checked]:opacity-100"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path
                        className="stroke-white"
                        d="M3 8L6 11L11 3.5"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        </Checkbox>
    );
}

interface CheckboxOptionsInterface {
    options: string[];
    selected: string[];
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
    previous: () => void;
    next: () => void;
}

export default function CheckboxOptions({
    options,
    selected,
    setSelected,
    previous,
    next,
}: CheckboxOptionsInterface) {
    const [error, setError] = useState(false);

    const toggleOption = (key, included) => {
        if (included) {
            setSelected((prev) => [...prev, key]);
        } else {
            setSelected((prev) => prev.filter((item) => item !== key));
        }
    };

    return (
        <>
            <span className="text-sm">
                <FormattedMessage id="Multiple selection" />
            </span>

            <div className="flex flex-col gap-y-2 mt-2">
                {options.map((item) => (
                    <StyledCheckbox
                        title={item}
                        enabled={selected?.includes(item)}
                        setEnabled={toggleOption}
                        key={item}
                    />
                ))}
            </div>
            {error && (
                <p className="text-red-600 text-sm">
                    <FormattedMessage id="Please select an option" />
                </p>
            )}
            <ProgressButtons
                previous={previous}
                nextLabel="Vote"
                next={() => {
                    if (selected?.length) {
                        next();
                    } else {
                        setError(true);
                    }
                }}
            />
        </>
    );
}
