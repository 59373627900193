import { motion } from "motion/react";
import { useEffect, useState } from "react";
import { ProgressButtons } from "./progressButtons";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAxios } from "@/utils/axios";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
interface ResultsInterface {
    question: {
        question: string;
        description: string;
        options?: string[];
        option_type: string;
    };
    next: () => void;
    hideNext?: boolean;
    selected: string | string[];
    pollId: string;
    mock?: boolean;
    lookback?: boolean;
}

export default function Results({
    question,
    next,
    hideNext,
    selected,
    pollId,
    mock,
    lookback = false,
    // Lookback is for the polls overview page where a user can look back at their answered polls
}: ResultsInterface) {
    const axios = useAxios();
    const [result, setResult] = useState<any>(null);
    const [showAll, setShowAll] = useState(false);
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    useEffect(() => {
        if (mock) {
            setResult({
                data: {
                    question: {
                        options: question.options?.map((item) => {
                            const percent = Math.floor(
                                Math.random() * (40 - 10) + 10,
                            );

                            return {
                                option: item,
                                count: percent / 2,
                                percentage: percent,
                            };
                        }),
                    },
                },
            });
        } else {
            const fetchData = async () => {
                try {
                    const { data: response } = await axios.get(
                        route("polls.user.results", pollId),
                    );
                    setResult(response);
                } catch (e) {
                    console.error(e);
                }
            };
            fetchData();
        }
    }, []);
    return (
        <div className="flex flex-col h-full grow">
            <p className="text-xl mb-1 font-medium line-clamp-3">
                {question.question}
            </p>
            {!lookback && (
                <span>
                    <FormattedMessage
                        id={
                            question.option_type === "radio"
                                ? "Single selection"
                                : "Multiple selection"
                        }
                    />
                </span>
            )}
            <div className="flex justify-between">
                <div>
                    {result && (
                        <FormattedMessage
                            id=":count votes"
                            values={{ count: result.data.total_participants }}
                        />
                    )}
                </div>
                {lookback &&
                    question?.options &&
                    question.options?.length > 5 && (
                        <div className="flex justify-end items-center">
                            <button
                                onClick={() => setShowAll((prev) => !prev)}
                                className="flex items-center"
                            >
                                <FormattedMessage
                                    id={showAll ? "Show less" : "Show all"}
                                />
                                <div className="bg-white/60 rounded-full p-1/2 ml-2">
                                    {showAll ? (
                                        <MinusIcon className="size-4" />
                                    ) : (
                                        <PlusIcon className="size-4" />
                                    )}
                                </div>
                            </button>
                        </div>
                    )}
            </div>
            <div
                className={`flex flex-col gap-y-2 grow ${lookback ? "justify-end" : "justify-start"}`}
            >
                {question?.options
                    ?.slice(0, showAll || !lookback ? Infinity : 5)
                    .map((item) => {
                        const data = result
                            ? result.data.question.options.find(
                                  (result) => result.option === item,
                              )
                            : null;
                        return (
                            <motion.div
                                key={item}
                                initial={{ width: 0 }}
                                animate={{ width: "100%" }}
                                className="shrink py-1"
                            >
                                <div className="flex gap-x-4 items-center">
                                    <div className="w-full rounded-lg bg-white/60 flex relative h-12 items-center justify-between overflow-hidden text-black">
                                        {data && (
                                            <motion.div
                                                initial={{ width: 0 }}
                                                animate={{
                                                    width: `${data?.percentage}%`,
                                                }}
                                                className="bg-white text-sm font-medium text-black text-center p-0.5 leading-none rounded-l-lg h-12 flex items-center justify-between pl-2 absolute"
                                            />
                                        )}
                                        <span className="z-20 pl-4">
                                            {item}
                                        </span>
                                        <div className="z-20 flex gap-x-2 items-center pr-4">
                                            {data && (
                                                <span>{data?.percentage}%</span>
                                            )}
                                            {selected.includes(item) ? (
                                                <CheckCircleIcon className="size-5 text-voice" />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        );
                    })}
            </div>
            {hideNext !== true && <ProgressButtons next={next} />}
        </div>
    );
}
