import { PollQuestion } from "@/types/poll";
import { colorIsDark } from "@/utils/color";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { FormattedMessage } from "react-intl";
import { AnimatePresence, motion } from "motion/react";

interface CardFrontInterface {
    flip: (e: boolean) => void;
    question: Pick<PollQuestion, "question" | "id">;
    coverImage: string;
    voteCount: number;
    closingAt?: string;
    setCompleteProfileOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    color: string;
    logoUrl: string;
    needsCompleteRegistration?: boolean;
    newTag?: boolean;
    location?: string;
    initialRenderFinished?: boolean;
}

export default function CardFront({
    flip,
    question,
    coverImage,
    voteCount,
    closingAt,
    setCompleteProfileOpen,
    color,
    logoUrl,
    needsCompleteRegistration,
    newTag,
    initialRenderFinished,
    location,
}: CardFrontInterface) {
    dayjs.extend(duration);
    dayjs.extend(relativeTime);

    return (
        <motion.button
            layout
            layoutId={`fullCard-${question.id}-${location}`}
            className={`rounded-2xl overflow-hidden w-full ${colorIsDark(color) ? "text-white" : "text-black"}`}
            onClick={() =>
                setCompleteProfileOpen && needsCompleteRegistration
                    ? setCompleteProfileOpen(true)
                    : flip(true)
            }
        >
            <AnimatePresence propagate>
                <motion.div
                    initial={{ height: initialRenderFinished ? "0" : "13rem" }}
                    animate={{ height: "13rem" }}
                    exit={{ height: 0, opacity: 0 }}
                >
                    <div className="relative h-full">
                        <img
                            src={logoUrl}
                            className="h-8 absolute z-50 top-4 left-4 w-auto"
                            alt=""
                        />
                        {coverImage !== "/images/common/noimage.png" && (
                            <div className="w-full h-full absolute bg-linear-to-l from-transparent from-0% via-black/16 via-55% to-black/40 z-10 "></div>
                        )}
                        <img
                            src={coverImage}
                            className="w-full h-full object-cover"
                            alt=""
                        />
                        {newTag && (
                            <div className="absolute right-5 top-5 text-white bg-gray-900 rounded-lg capitalize px-2 py-1">
                                <FormattedMessage id="New" />
                            </div>
                        )}
                    </div>
                </motion.div>
                <motion.div
                    className="-mt-6 z-10 rounded-2xl w-full relative"
                    style={{ backgroundColor: color }}
                    initial={{
                        height: initialRenderFinished ? "26rem" : "13rem",
                    }}
                    animate={{ height: "13rem" }}
                    exit={{ height: "26rem", opacity: 20 }}
                >
                    <div className="flex flex-col h-full items-start p-6">
                        <motion.h3
                            className="font-medium grow text-left text-xl"
                            layoutId={`title-${question.id}-${location}`}
                            exit={{
                                marginTop: "1.25rem",
                                marginLeft: "-0.25rem",
                            }}
                            transition={{
                                default: { duration: 0 },
                                layout: { duration: 0 },
                            }}
                            animate={false}
                            initial={false}
                            layout={false}
                        >
                            {question.question}
                        </motion.h3>
                        <motion.span className="text-sm" exit={{ opacity: 0 }}>
                            <FormattedMessage
                                id=":count votes"
                                values={{ count: voteCount }}
                            />
                            {closingAt && (
                                <FormattedMessage
                                    id=" · :duration left"
                                    values={{
                                        duration: dayjs
                                            .duration(
                                                dayjs(closingAt).diff(dayjs()),
                                            )
                                            .humanize(),
                                    }}
                                />
                            )}
                        </motion.span>
                    </div>
                </motion.div>
            </AnimatePresence>
        </motion.button>
    );
}
